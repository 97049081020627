import React, { useState, createContext, useContext } from "react"

export const SearchValueContext = createContext()

export function SearchValueProvider(props) {
  const [searchValue, setSearchValue] = useState("")

  function addSearchValue(newSearchValue) {
    setSearchValue(newSearchValue)
  }

  const contextValue = {
    setSearchValue,
    searchValue,
    addSearchValue,
  }

  return (
    <SearchValueContext.Provider value={contextValue}>
      {props.children}
    </SearchValueContext.Provider>
  )
}

export const useSearchValueContext = () => useContext(SearchValueContext)
