exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-awesome-js": () => import("./../../../src/pages/awesome.js" /* webpackChunkName: "component---src-pages-awesome-js" */),
  "component---src-pages-cassandra-toolkit-js": () => import("./../../../src/pages/cassandra.toolkit.js" /* webpackChunkName: "component---src-pages-cassandra-toolkit-js" */),
  "component---src-pages-cassandra-wiki-js": () => import("./../../../src/pages/cassandra-wiki.js" /* webpackChunkName: "component---src-pages-cassandra-wiki-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-training-cassandra-js": () => import("./../../../src/pages/training-cassandra.js" /* webpackChunkName: "component---src-pages-training-cassandra-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-templates-article-single-page-js": () => import("./../../../src/templates/articleSinglePage.js" /* webpackChunkName: "component---src-templates-article-single-page-js" */),
  "component---src-templates-news-feed-template-js": () => import("./../../../src/templates/newsFeedTemplate.js" /* webpackChunkName: "component---src-templates-news-feed-template-js" */),
  "component---src-templates-resources-template-js": () => import("./../../../src/templates/resourcesTemplate.js" /* webpackChunkName: "component---src-templates-resources-template-js" */),
  "component---src-templates-single-news-feed-js": () => import("./../../../src/templates/singleNewsFeed.js" /* webpackChunkName: "component---src-templates-single-news-feed-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-video-page-template-js": () => import("./../../../src/templates/videoPageTemplate.js" /* webpackChunkName: "component---src-templates-video-page-template-js" */)
}

